import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Strong, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Піцерія Старе Місто - Справжня італійська піца у вашому місті
			</title>
			<meta name={"description"} content={"Насолоджуйтесь справжньою італійською піцею та іншими смачними стравами у затишній атмосфері."} />
			<meta property={"og:title"} content={"Головна | Піцерія Старе Місто - Справжня італійська піца у вашому місті"} />
			<meta property={"og:description"} content={"Насолоджуйтесь справжньою італійською піцею та іншими смачними стравами у затишній атмосфері."} />
			<meta property={"og:image"} content={"https://earthexplorershub.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://earthexplorershub.com/img/6635075.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://earthexplorershub.com/img/6635075.png"} />
			<meta name={"msapplication-TileImage"} content={"https://earthexplorershub.com/img/6635075.png"} />
			<meta name={"msapplication-TileColor"} content={"https://earthexplorershub.com/img/6635075.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.2) 0%,rgba(4, 8, 12, 0.2) 100%),--color-darkL2 url(https://earthexplorershub.com/img/1.jpg) center/cover"
			padding="200px 0 80px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text
							color="--lightD2"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
							font="--headline1"
						>
							Старе Місто
						</Text>
						<Text font="normal 400 22px/1.2 &quot;Source Sans Pro&quot;, sans-serif" margin="10px 0 150px">
						Ласкаво просимо до піцерії "Старе Місто", де традиції італійської кухні оживають у кожній страві. Ми пропонуємо вам насолодитись справжньою італійською піцою, приготованою з любов'ю та турботою. Наші майстри кулінарії використовують лише свіжі та якісні інгредієнти, щоб кожен шматочок був досконалим.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
				Наші спеціальні пропозиції
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					<Strong>
					Сімейний вечір: 
					</Strong>
					<br />
					замовте дві великі піци та отримайте безкоштовно десерт.
					<br />
					<br />
					<Strong>
					Обідня пропозиція: 
					</Strong>
					<br />
					знижка 20% на всі піци з 12:00 до 15:00.
					<br />
					<br />
					<Strong>
					День народження: 
					</Strong>
					<br />
					святкуйте свій день народження у нас та отримайте безкоштовну піцу на вибір.
					<br />
					<br />
					<Strong>
					Вихідний з друзями: 
					</Strong>
					<br />
					замовте чотири піци та отримайте знижку 10%.
					<br />
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://earthexplorershub.com/img/2.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://earthexplorershub.com/img/3.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						font="--base"
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--light"
						letter-spacing="2px"
					>
						Ми пишаємося нашим асортиментом піц
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наші піци
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
				Маргарита: класична піца з томатним соусом, моцарелою та базиліком.
					<br /><br />
Пепероні: піца з гострою ковбасою пепероні, моцарелою та томатним соусом.
					<br /><br />
Капрічоза: піца з артишоками, шинкою, грибами та оливками.
					<br /><br />
Чотири сири: піца з моцарелою, горгонзолою, пармезаном та ементалем.
					<br /><br />
Гавайська: піца з ананасом, шинкою та моцарелою.
					<br /><br />
Кожна піца готується на дров'яній печі, що додає неповторного смаку та аромату.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Наш інтер'єр та атмосфера
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Піцерія "Старе Місто" - це не лише смачні страви, а й затишна атмосфера. Наш інтер'єр виконаний у стилі старовинного італійського міста, що додає особливого шарму. Ми подбали про те, щоб кожен наш гість відчув себе як вдома. Затишні зали, приємна музика та привітний персонал зроблять ваш візит незабутнім.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Завітайте до нас
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});